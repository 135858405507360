<template>
  <div class="popup-layer-wrap pay-list" :class="{ on, ing }">
    <div class="__layer">
      <p class="tit">급료단가표</p>
      <div class="search-box">
        <input
          v-model="schKeyword"
          type="text"
          class="text"
          placeholder="숙박업종, 직종"
          @keyup.enter="search"
        />
        <button class="btn" @click="search">검색</button>
      </div>
      <div class="scroll-wrap">
        <table class="tbl">
          <colgroup>
            <col style="width: 25%" />
            <col style="width: 25%" />
            <col style="width: 25%" />
            <col style="width: 25%" />
          </colgroup>
          <thead>
            <tr>
              <th>숙박업 유형 명</th>
              <th>직종 명</th>
              <th>세부직종 명</th>
              <th>표준 단가</th>
              <!-- <th>평균 단가</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(feeItem, idx) in feeList" :key="`fi_${idx}`">
              <td>{{ feeItem.jobCategoryName }}</td>
              <td>{{ feeItem.positionName }}</td>
              <td>{{ feeItem.positionDtlName }}</td>
              <td>{{ insertCommaToNumber(feeItem.stdPrice) }}</td>
              <!-- <td>{{ feeItem.avgPrice }}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <button class="btn closed" type="button" @click="close">닫기</button>
    </div>
  </div>
</template>

<script>
import popupMixin from 'Mixins/popup'
import { getFeeList } from 'Api/modules'

export default {
  mixins: [popupMixin],
  data() {
    return {
      feeList: [],
      schKeyword: '',
    }
  },
  watch: {
    async visible(newVal) {
      if (newVal) {
        const res = await getFeeList({ schKeyword: this.schKeyword })
        this.feeList = res.content
      }
    },
  },
  methods: {
    async search() {
      const res = await getFeeList({ schKeyword: this.schKeyword })
      this.feeList = res.content
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .popup-layer-wrap .__layer .tit {
    padding: 0 0 22px 0;
    height: unset;
  }
}
@media (max-width: 1024px) {
  .popup-layer-wrap .__layer {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
